$(function() {

	// mask phone
	$('.phone').mask("+7 (999) 999-99-99",{placeholder:"_"});

	$('.sl-popover').popover({
	    container: 'body',
	    trigger: 'click'
	});

	// header modificate
	$(window).scroll(function() {
		if ( this.pageYOffset > 0 ) {
			$('header').addClass('active');
		} else {
			$('header').removeClass('active');
		}        
	}).trigger('scroll');

	$('.header-mnav-btnmenu').on('click', function() {
		$(this).toggleClass('active');
		$('.global-menu').toggleClass('active');
		$('html').toggleClass('gmactive');
		return false;
	});

    $('.sl-welcome').slick({
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		// autoplay: true,
		autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					dots: false
				}
			}
		]
	});

 	if ($(".instagram-line-sl").length > 0) {
        var l = function() {
            var e = $(window).innerWidth(),
                n = 0,
                t = $(".container").innerWidth();

            return n = (e - t) / 2 + 15;
            n = parseInt(n);
            n;
        };

        l();

        var d = new Swiper(".instagram-line-sl", {
            init: !1,
            slidesPerView: "auto",
            freeMode: !0,
            speed: 1e3,
            slidesOffsetBefore: l(),
            slidesOffsetAfter: 15,
            spaceBetween: 30,
            slideToClickedSlide: !0,
            breakpoints: {
				991: {
					spaceBetween: 10,
					slidesOffsetAfter: 5,
				}
			},
            navigation: {
                nextEl: ".instagram-line-sl .swiper-button-next",
                prevEl: ".instagram-line-sl .swiper-button-prev"
            },
            on: {
			    init: function () {
					$('.instagram-line-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left - 56 })
			    }
			}
        });

        d.init();

        $(window).resize(function() {
            d.params.slidesOffsetBefore = l();
            d.update(!0);
            $('.instagram-line-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left - 56 })
            0 === d.activeIndex && d.slideTo(0)
        });

        $(window).on("orientationchange", function() {
            d.params.slidesOffsetBefore = l();
            d.update(!0);
            $('.instagram-line-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left - 56 })
            0 === d.activeIndex && d.slideTo(0)
        });
    };

    $('.sl-team').slick({
		arrows: true,
		dots: true,
		infinite: false,
		speed: 500,
		responsive: [
		    {
				breakpoint: 991,
				settings: {
					dots: false,
				}
		    }
		]
	});

	// if ( $('.sl-details').length ) {

	// 	weightdetailssl()
		  
	// 	// sait rotate changes
	// 	$(window).on("orientationchange", function() {
	// 		weightdetailssl()
	// 	});

	// 	// sait resize changes
	// 	$(window).resize(function() {
	// 		weightdetailssl()
	// 	});

	//     $('.sl-details').slick({
	// 		arrows: true,
	// 		dots: false,
	// 		infinite: true,
	// 		speed: 500,
	// 		variableWidth: true,
	// 		responsive: [
	// 		    {
	// 				breakpoint: 1345,
	// 				settings: {
	// 					arrows: false,
	// 				}
	// 		    }
	// 		]
	// 	});

	// };


 	if ($(".sl-details-wrapper").length > 0) {
        var loffset = function() {
            var e = $(window).innerWidth(),
                n = 0,
                t = $(".container").innerWidth();

            return n = (e - t) / 2 + 15;
            n = parseInt(n);
            n;
        };

        loffset();

        var prsl = new Swiper(".sl-details-wrapper", {
            init: !1,
            slidesPerView: "auto",
            freeMode: !0,
            speed: 1e3,
            slidesOffsetBefore: loffset(),
            slidesOffsetAfter: 15,
            spaceBetween: 30,
            slideToClickedSlide: !0,
            breakpoints: {
				991: {
					spaceBetween: 10,
					slidesOffsetAfter: 5,
				}
			},
            navigation: {
                nextEl: ".sl-details-wrapper .swiper-button-next",
                prevEl: ".sl-details-wrapper .swiper-button-prev"
            },
            on: {
			    init: function () {
					$('.sl-details-wrapper .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
			    }
			}
        });

        prsl.init();

        $(window).resize(function() {
            prsl.params.slidesOffsetBefore = loffset();
            prsl.update(!0);
            $('.sl-details-wrapper .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
            0 === prsl.activeIndex && prsl.slideTo(0)
        });

        $(window).on("orientationchange", function() {
            prsl.params.slidesOffsetBefore = loffset();
            prsl.update(!0);
            $('.sl-details-wrapper .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
            0 === prsl.activeIndex && prsl.slideTo(0)
        });
    };

    $('.sl-service').slick({
		arrows: true,
		dots: true,
		infinite: false,
		speed: 500,
		responsive: [
		    {
				breakpoint: 1199,
				settings: {
					dots: false,
				}
		    }
		]
	});

    $('.portfolio-sl').slick({
		dots: false,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear'
	});

    $('.sinterier-sl').slick({
		arrows: true,
		dots: true,
		infinite: true,
		speed: 500,
		responsive: [
		    {
				breakpoint: 991,
				settings: {
					dots: false,
				}
		    }
		]
	});

	$('.sinterier-sl').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$('.sl-popover').popover('hide');
	});

 	if ($(".sl-project-sl").length > 0) {
        var loffset = function() {
            var e = $(window).innerWidth(),
                n = 0,
                t = $(".container").innerWidth();

            return n = (e - t) / 2;
            n = parseInt(n);
            n;
        };

        loffset();

        var prsl = new Swiper(".sl-project-sl", {
            init: !1,
            slidesPerView: "auto",
            freeMode: !0,
            speed: 1e3,
            slidesOffsetBefore: loffset(),
            slidesOffsetAfter: 15,
            spaceBetween: 30,
            slideToClickedSlide: !0,
            breakpoints: {
				991: {
					spaceBetween: 10,
					slidesOffsetAfter: 5,
				}
			},
            navigation: {
                nextEl: ".sl-project-sl .swiper-button-next",
                prevEl: ".sl-project-sl .swiper-button-prev"
            },
            on: {
			    init: function () {
					$('.sl-project-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
			    }
			}
        });

        prsl.init();

        $(window).resize(function() {
            prsl.params.slidesOffsetBefore = loffset();
            prsl.update(!0);
            $('.sl-project-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
            0 === prsl.activeIndex && prsl.slideTo(0)
        });

        $(window).on("orientationchange", function() {
            prsl.params.slidesOffsetBefore = loffset();
            prsl.update(!0);
            $('.sl-project-sl .swiper-button-prev').css({ marginLeft: $('.container').offset().left })
            0 === prsl.activeIndex && prsl.slideTo(0)
        });
    };

	$('.sl-process').slick({
		dots: true,
		infinite: true,
		speed: 500,
		adaptiveHeight: true,
		responsive: [
		    {
				breakpoint: 991,
				settings: {
					dots: false
				}
		    }
		]
	});

	if ( $('#map').length ) {
		initMap()
	};

});

function initMap() {
    var map = new google.maps.Map(
        document.getElementById('map'), {
            zoom: 17,
            center: {
                lat: 45.048552074577934,
                lng: 38.96133549999995
            },
            scrollwheel: false,
            styles: [{
                stylers: [{
                    saturation: -100
                }]
            }],
        });

    new google.maps.Marker({
        position: {
            lat: 45.048552074577934,
            lng: 38.96133549999995
        },
        map: map,
        icon: 'assets/images/ic/pinmap.svg'
    });
};